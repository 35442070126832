import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';

const Avatar = ({ imageId, firstName, lastName, ...props }) => {
    const isDefaultAvatar = imageId.includes('avatar_default');
    const initials = [firstName || '', lastName || ''].map(x => x.charAt(0).toUpperCase()).join('');
    const transformations = ['avatar'];

    if (isDefaultAvatar) {
        transformations.push(`l_text:avatar_initials:${initials}`);
    }

    return <Image publicId={imageId} transformation={transformations.join(',')} {...props} />;
};

Avatar.propTypes = {
    imageId: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
};

Avatar.defaultProps = {
    firstName: '',
    lastName: '',
};

export default Avatar;
