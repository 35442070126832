import React from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import { adopt } from 'react-adopt';
import { GlobalConsumer } from 'site/context';
import UserNav from 'site/user-nav';
import withClasses from 'common/with-classes';
import Collapsible from 'common/collapsible';
import Button from 'common/button';
import Link from 'common/link';
import Avatar from 'common/avatar';

const NavContainer = withClasses('nav', 'flex items-center');

const PrimaryAction = withClasses(
    Link,
    'transition text-sm font-bold text-gray-darker group-hover:text-gray-base'
);

const DropdownPose = posed.div({
    exit: { opacity: 0, translateY: -10 },
    enter: { opacity: 1, translateY: 0 },
});

/* eslint-disable */
const AuthNavState = adopt(
    {
        global: <GlobalConsumer />,
    },
    ({ global }) => ({
        ...global,
    })
);
/* eslint-enable */

const AuthNav = ({ allowRegister }) => (
    <AuthNavState>
        {({ user }) => {
            if (user) {
                return (
                    <NavContainer>
                        <PrimaryAction to="/dashboard" legacy>
                            Dashboard
                        </PrimaryAction>
                        <div className="w-px bg-gray-lighter h-8 mx-6" />
                        <Collapsible>
                            {({ expand, collapse, isExpanded }) => (
                                <div
                                    onMouseEnter={expand}
                                    onMouseLeave={collapse}
                                    className="relative"
                                >
                                    <Avatar
                                        firstName={user.firstName}
                                        lastName={user.lastName}
                                        imageId={user.avatar.id}
                                        className="w-14 h-14 rounded-full inline-block cursor-pointer"
                                    />
                                    <PoseGroup withParent={false}>
                                        {isExpanded && (
                                            <DropdownPose
                                                key="dropdown"
                                                className="absolute pin-r pin-from-t"
                                            >
                                                <UserNav
                                                    classNames={{
                                                        container:
                                                            'bg-white rounded border border-gray-lighter shadow  whitespace-no-wrap overflow-hidden',
                                                        link:
                                                            'text-14 text-gray-70 hover:bg-gray-10 block py-4 px-6',
                                                    }}
                                                />
                                            </DropdownPose>
                                        )}
                                    </PoseGroup>
                                </div>
                            )}
                        </Collapsible>
                    </NavContainer>
                );
            }

            return (
                <NavContainer>
                    <PrimaryAction to="/sign-in">Sign In</PrimaryAction>
                    {allowRegister && (
                        <Button
                            component={Link}
                            color="gradient"
                            size="lg"
                            to="/choice"
                            className="font-medium py-6 px-8 ml-8 text-18 leading-none"
                            legacy
                        >
                            Get Started
                        </Button>
                    )}
                </NavContainer>
            );
        }}
    </AuthNavState>
);

AuthNav.propTypes = {
    allowRegister: PropTypes.bool,
};

AuthNav.defaultProps = {
    allowRegister: true,
};

export default AuthNav;
