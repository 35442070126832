import React from 'react';
import PropTypes from 'prop-types';
import { Link as ReactRouterLink } from 'react-router-dom';

import { isLegacyUrl } from 'utils';

/**
 * Extension of the `ReactRouter.Link` component from to support
 * external links. External URLs are rendered as a standard `<a>`
 * and will open in a new window. Will pass along any provided
 * props to the underlying `Link` or `a` component.
 *
 * For more information see the `ReactRouter.Link` documentation:
 * https://reacttraining.com/react-router/web/api/Link
 */
const Link = ({ to, external, legacy, children, ...rest }) => {
    if (to.startsWith('/') && !legacy && !isLegacyUrl(to) && !external) {
        return (
            <ReactRouterLink to={to} {...rest}>
                {children}
            </ReactRouterLink>
        );
    }

    const linkProps = external ? { target: '_blank', rel: 'noopener noreferrer' } : {};
    return (
        <a href={to} {...linkProps} {...rest}>
            {children}
        </a>
    );
};

Link.propTypes = {
    /** Url or fragment to navigate to */
    to: PropTypes.string.isRequired,

    /** Link directs to a legacy (BackboneJS) portion of the app */
    legacy: PropTypes.bool,

    /** Force the link to open in a new window/tab */
    external: PropTypes.bool,

    /** @ignore */
    children: PropTypes.node,
};

Link.defaultProps = {
    external: false,
    legacy: false,
    children: null,
};

export default Link;
