import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from 'common/link';
import Icon from 'common/icon';

const FooterSocialLink = ({ network, icon, color, url }) => (
    <Link
        to={url}
        className={cx(
            'ml-2 inline-flex justify-center items-center w-10 h-10 bg-gray-dark text-gray-darkest hover:text-white',
            `hover:bg-${color || network}`
        )}
    >
        <Icon icon={icon || network} type="brand" size="lg" />
    </Link>
);

FooterSocialLink.propTypes = {
    network: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.string,
    color: PropTypes.string,
};

FooterSocialLink.defaultProps = {
    icon: '',
    color: '',
};

export default FooterSocialLink;
