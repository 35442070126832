import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import Store from 'conf-store';

import config from 'config';
import Status from 'routing/status';
import Container from 'common/container';
import Button from 'common/button';
import { Image } from 'cloudinary-react';

const defaults = new Store({
    background: {
        $filter: 'code',
        404: 'error-bg-strikeout',
        $default: 'error-bg-fumble',
    },
    title: {
        $filter: 'code',
        404: 'Swing and a miss!',
        $default: 'Boooo!',
    },
    message: {
        $filter: 'code',
        404: "Whatever you were looking for, it certainly isn't here.",
        $default:
            "We really fumbled this one. We'll take a look at the playbook and make sure this doesn't happen in the future.",
    },
});

class ErrorView extends Component {
    static propTypes = {
        history: PropTypes.shape({
            goBack: PropTypes.func.isRequired,
        }).isRequired,
        code: PropTypes.number,
        title: PropTypes.string,
        message: PropTypes.string,
        background: PropTypes.string,
        error: PropTypes.shape({
            statusCode: PropTypes.number,
            message: PropTypes.string,
            networkError: PropTypes.shape({
                statusCode: PropTypes.number,
            }),
        }),
        stack: PropTypes.arrayOf(PropTypes.shape({})),
        showError: PropTypes.bool,
    };

    static defaultProps = {
        code: null,
        title: null,
        message: null,
        background: null,
        error: null,
        stack: null,
        showError: config('/env') === 'development',
    };

    get code() {
        const { code, error } = this.props;
        if (code) return code;
        if (get(error, 'statusCode')) return get(error, 'statusCode');
        if (get(error, 'networkError.statusCode')) return get(error, 'statusCode');
        return 500;
    }

    get background() {
        const { background } = this.props;
        return background || defaults.get('/background', { code: this.code });
    }

    get title() {
        const { title } = this.props;
        return title || defaults.get('/title', { code: this.code });
    }

    get message() {
        const { message } = this.props;
        return message || defaults.get('/message', { code: this.code });
    }

    get serializedError() {
        const { error, stack } = this.props;
        return `${error ? error.toString() : ''}\n${stack ? stack.toString('\n') : ''}`;
    }

    get shouldShowError() {
        const { showError, error } = this.props;
        return showError && error;
    }

    render() {
        const { history } = this.props;

        return (
            <Status code={this.code}>
                <div className="flex-1 relative flex flex-col justify-center">
                    <Image
                        publicId={this.background}
                        alt=""
                        transformation="hero-bg"
                        className="object-cover object-center block w-full h-full absolute pin-y pin-x z-0 saturate-0"
                    />
                    <span className="absolute pin-y pin-x z-10 bg-white opacity-75" />

                    <Container className="text-center py-32 relative z-20">
                        <p
                            className="font-black text-green-30 opacity-40 -mb-32 relative z-0"
                            css={{
                                fontSize: '16rem',
                                lineHeight: 0.75,
                            }}
                        >
                            {this.code}
                        </p>
                        <div className="mx-auto max-w-md mt-4 relative z-10">
                            <h1
                                className="font-black text-gray-darkest leading-tight uppercase"
                                css={{ fontSize: '5rem' }}
                            >
                                {this.title}
                            </h1>
                            <p className="text-21 leading-loose text-gray-dark mt-16">
                                {this.message}
                            </p>
                            <Button
                                color="green"
                                size="md"
                                className="text-16 font-medium mt-16"
                                onClick={() => history.goBack()}
                            >
                                Go back
                            </Button>
                            {this.shouldShowError && (
                                <div className="my-12">
                                    <div className="bg-white rounded-lg shadow-lg p-8 max-w-full max-h-1/2-screen w-md mx-auto font-mono text-14 text-left overflow-auto">
                                        <pre className="block">{this.serializedError}</pre>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Container>
                </div>
            </Status>
        );
    }
}

export default withRouter(ErrorView);
