import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';
import cx from 'classnames';

import { formatCurrency } from 'utils';

const CampaignResult = ({
    uri,
    isComplete,
    title,
    image,
    recipientName,
    amountRaised,
    metricName,
}) => (
    <a
        href={uri}
        data-testid="result-campaign"
        className={cx('block py-6 px-12 border-t border-gray-70 text-white hover:bg-gray-70', {
            'opacity-40 hover:opacity-100': isComplete,
        })}
    >
        <div className="flex items-start -ml-8">
            <div className="flex-no-shrink w-48 pl-8">
                {image.id && (
                    <Image
                        publicId={image.id}
                        alt=""
                        className={cx('block w-full h-24 object-cover', {
                            'saturate-0': isComplete,
                        })}
                        transformation="campaign-card"
                    />
                )}
            </div>
            <div className="flex-grow pl-8 truncate">
                <h2 className="text-16 font-medium truncate">{title}</h2>
                <p className="text-14 mt-1 font-medium truncate">{recipientName}</p>
                {isComplete ? (
                    <p className="text-12 mt-3 font-medium truncate">
                        Complete - {formatCurrency(amountRaised, { cents: 'never' })} raised
                    </p>
                ) : (
                    <p className="text-12 mt-3 font-medium capitalize truncate">
                        {metricName} campaign
                    </p>
                )}
            </div>
        </div>
    </a>
);

CampaignResult.propTypes = {
    uri: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    recipientName: PropTypes.string.isRequired,
    isComplete: PropTypes.bool.isRequired,
    amountRaised: PropTypes.number.isRequired,
    metricName: PropTypes.string.isRequired,
    image: PropTypes.shape({ id: PropTypes.string }),
};

CampaignResult.defaultProps = {
    image: {},
};

export default CampaignResult;
