import PropTypes from 'prop-types';

import useBreakpoint from 'hooks/use-breakpoint';

/**
 * A component which will only render its children when
 * the browser is less wide than the large breakpoint.
 * Inverse of the LargeScreen component, utilizes the
 * `<ScreenSize>` render-prop component under the hood.
 */
const SmallScreen = ({ children }) => {
    const { lg } = useBreakpoint();
    return lg ? null : children;
};

SmallScreen.propTypes = {
    /** Node(s) to be rendered on small screens */
    children: PropTypes.node,
};

SmallScreen.defaultProps = {
    children: null,
};

export default SmallScreen;
