import React from 'react';
import { DateTime } from 'luxon';

import Sitemap from 'site/sitemap';
import Container from 'common/container';
import LargeScreen from 'common/large-screen';
import Link from 'common/link';

import FooterSocialLink from './social-link';

const SiteFooter = () => (
    <div className="py-16 bg-gray-darkest">
        <Container className="max-w-xxxl">
            <LargeScreen>
                <Sitemap
                    className="flex justify-between mb-16 pb-16 border-0 border-b border-solid border-gray-darker"
                    sectionClassName="pr-8 text-xs font-medium"
                />
            </LargeScreen>
            <section>
                <p className="text-xs font-medium leading-loose m-0 text-gray-base">
                    <span className="text-white">
                        Copyright &copy;
                        {DateTime.local().toFormat('y')} Pledge Platform Inc
                    </span>
                    <span className="px-1/2em">•</span>
                    <Link
                        to="/tos"
                        className="whitespace-nowrap text-gray-base hover:text-white"
                        legacy
                    >
                        Terms of Service
                    </Link>
                    <span className="px-1/2em">•</span>
                    <Link
                        to="/privacy"
                        className="whitespace-nowrap text-gray-base hover:text-white"
                        legacy
                    >
                        Privacy Policy
                    </Link>
                </p>
                <div className="mt-8 -ml-2">
                    <FooterSocialLink
                        network="facebook"
                        icon="facebook-f"
                        url="https://www.facebook.com/gopledgeit"
                    />
                    <FooterSocialLink network="twitter" url="https://twitter.com/pledgeit" />
                    <FooterSocialLink network="youtube" url="https://www.youtube.com/user/pldgit" />
                    <FooterSocialLink
                        network="linkedin"
                        icon="linkedin-in"
                        url="https://www.linkedin.com/company/pldgit"
                    />
                </div>
            </section>
        </Container>
    </div>
);

export default SiteFooter;
