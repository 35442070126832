import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';

const TemplateResult = ({ uri, title, image, recipientName }) => (
    <a
        href={uri}
        className="block py-6 px-12 border-t border-gray-70 text-white hover:bg-gray-70"
        data-testid="result-template"
    >
        <div className="flex items-start -ml-8">
            <div className="flex-no-shrink w-48 pl-8">
                {image.id && (
                    <Image
                        publicId={image.id}
                        alt=""
                        className="block w-full h-24 object-cover"
                        transformation="campaign-card"
                    />
                )}
            </div>
            <div className="flex-grow pl-8 truncate">
                <h2 className="text-16 font-medium truncate">{title}</h2>
                <p className="text-14 mt-1 font-medium truncate">{recipientName}</p>
            </div>
        </div>
    </a>
);

TemplateResult.propTypes = {
    uri: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    recipientName: PropTypes.string.isRequired,
    image: PropTypes.shape({ id: PropTypes.string }),
};

TemplateResult.defaultProps = {
    image: {},
};

export default TemplateResult;
