import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import useScrollLock from 'hooks/use-scroll-lock';
import Search from 'site/search';

const SearchPose = posed.div({
    enter: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -50 },
});

const MobileSearch = ({ onClose }) => {
    useScrollLock();

    return (
        <div className="fixed pin-t pin-x z-40">
            <SearchPose className="bg-white py-6 w-full">
                <div className="mx-auto" css={{ maxWidth: 480 }}>
                    <Search onClose={onClose} />
                </div>
            </SearchPose>
        </div>
    );
};

MobileSearch.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default MobileSearch;
