import { useContext, useEffect, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';

import Global from 'site/context';

export default () => {
    const { registerScrollLock, deregisterScrollLock } = useContext(Global);
    const cid = useRef(uniqueId('scroll_lock'));

    useEffect(() => {
        registerScrollLock(cid);
        return () => deregisterScrollLock(cid);
    }, []);

    return null;
};
