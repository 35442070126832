import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import posed from 'react-pose';
import { GlobalConsumer } from 'site/context';

import Link from 'common/link';

const LinkPose = posed.div({
    expanded: { translateY: 0, opacity: 1 },
    collapsed: { translateY: -10, opacity: 0 },
});

const UserNav = ({ classNames }) => (
    <GlobalConsumer>
        {({ signOut }) => (
            <div className={classNames.container}>
                <LinkPose>
                    <Link to="/dashboard" className={classNames.link}>
                        Dashboard
                    </Link>
                </LinkPose>
                <LinkPose>
                    <Link to="/manage/account/profile" className={classNames.link}>
                        Account Settings
                    </Link>
                </LinkPose>
                <LinkPose>
                    <button
                        type="button"
                        className={cx(classNames.link, 'text-left w-full')}
                        onClick={signOut}
                    >
                        Sign Out
                    </button>
                </LinkPose>
            </div>
        )}
    </GlobalConsumer>
);

UserNav.propTypes = {
    classNames: PropTypes.shape({
        container: PropTypes.string,
        link: PropTypes.string,
    }),
};

UserNav.defaultProps = {
    classNames: {
        container: '',
        link: '',
    },
};

export default UserNav;
