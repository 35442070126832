import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import hoistStatics from 'hoist-non-react-statics';

export default (PassedComponent, classNames) => {
    const NewComponent = ({ className, ...props }) => (
        <PassedComponent className={cx(classNames, className)} {...props} />
    );

    NewComponent.propTypes = {
        className: PropTypes.string,
    };

    NewComponent.defaultProps = {
        className: '',
    };

    NewComponent.displayName = `withClasses(${PassedComponent.displayName ||
        PassedComponent.name})`;
    NewComponent.WrappedComponent = PassedComponent;

    return hoistStatics(NewComponent, PassedComponent);
};
