import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useThrottle } from 'use-throttle';
import useKey from '@rooks/use-key';

import Icon from 'common/icon';
import Results from './results';

const Search = ({ onClose }) => {
    const [query, setQuery] = useState('');
    const throttledQuery = useThrottle(query, 100);
    const shouldDisplayResults = throttledQuery.length > 2;
    useKey(['Escape'], onClose);

    return (
        <div className="relative sm:px-8">
            <div className="flex items-center relative">
                <div className="w-14 flex-no-shrink">
                    <button
                        type="button"
                        className="link-gray text-lg p-2"
                        data-testid="close"
                        onClick={onClose}
                    >
                        <Icon icon="times" size="1x" />
                    </button>
                </div>
                <div className="flex-grow">
                    <input
                        type="text"
                        value={query}
                        data-testid="query"
                        className="rounded bg-gray-20 py-4 pl-6 pr-14 text-sm text-gray-70 font-medium w-full"
                        placeholder="Search for a campaign"
                        onChange={({ target }) => setQuery(target.value)}
                        autoFocus
                    />
                </div>
                <div className="absolute pin-y pin-r flex items-center mr-4">
                    <Icon icon="search" type="solid" size="1x" />
                </div>
            </div>
            {shouldDisplayResults && <Results query={throttledQuery} />}
        </div>
    );
};

Search.propTypes = {
    onClose: PropTypes.func.isRequired,
};
export default Search;
