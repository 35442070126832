import React from 'react';
import PropTypes from 'prop-types';
import { adopt } from 'react-adopt';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import get from 'lodash/get';

import SearchResults from './search-results';

export const GET_SEARCH_RESULTS = gql`
    query GetSearchResults($query: String!) {
        results: siteSearch(query: $query, limit: 5) {
            title
            uri
            type
            image {
                id
            }
            isComplete
            amountRaised
            recipientName
            metricName
            groupType
        }
    }
`;

/* eslint-disable react/prop-types, react/destructuring-assignment */
const Wrapper = adopt(
    {
        searchQuery: ({ query, render }) => (
            <Query query={GET_SEARCH_RESULTS} variables={{ query, limit: 5 }}>
                {render}
            </Query>
        ),
    },
    ({ searchQuery }) => ({
        error: searchQuery.error,
        loading: searchQuery.loading,
        results: get(searchQuery, 'data.results', []),
    })
);
/* eslint-enable react/prop-types, react/destructuring-assignment */

const SearchResultsContainer = ({ query }) => (
    <Wrapper query={query}>
        {({ error, loading, results }) => {
            if (error) throw error;

            return <SearchResults query={query} isSearching={loading} results={results} />;
        }}
    </Wrapper>
);

SearchResultsContainer.propTypes = {
    query: PropTypes.string.isRequired,
};

export default SearchResultsContainer;
