import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Icon from 'common/icon';
import CampaignResult from './result-campaign';
import GroupResult from './result-group';
import TemplateResult from './result-template';

const SearchResults = ({ query, isSearching, results }) => {
    const hasNoResults = !isSearching && !results.length;
    const hasResults = !isSearching && !!results.length;

    return (
        <div className="absolute pin-l pin-from-t pt-2 sm:max-w-full lg:pl-14" css={{ width: 480 }}>
            <div className="bg-gray-80 shadow-lg">
                {isSearching && (
                    <p
                        className="text-white py-6 px-12 font-medium text-center"
                        data-testid="results-loading"
                    >
                        <Icon icon="spinner" size="lg" className="mr-4 text-gray-40" spin />
                        Searching&hellip;
                    </p>
                )}

                {hasNoResults && (
                    <p
                        className="text-gray-40 py-6 px-12 font-medium text-center"
                        data-testid="results-empty"
                    >
                        Sorry, no matches for{' '}
                        <span className="text-white">&ldquo;{query}&rdquo;</span>
                    </p>
                )}

                {hasResults &&
                    results.map((result, i) => (
                        <div key={result.uri} className={cx('border-gray-70', { 'border-t': i })}>
                            {(() => {
                                switch (result.type) {
                                    case 'Campaign':
                                        return <CampaignResult {...result} />;
                                    case 'CampaignGroup':
                                        return <GroupResult {...result} />;
                                    case 'Template':
                                        return <TemplateResult {...result} />;
                                    default:
                                        return null;
                                }
                            })()}
                        </div>
                    ))}
            </div>
        </div>
    );
};

SearchResults.propTypes = {
    query: PropTypes.string.isRequired,
    isSearching: PropTypes.bool.isRequired,
    results: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.oneOf(['Campaign', 'CampaignGroup', 'Template']).isRequired,
            title: PropTypes.string.isRequired,
            amountRaised: PropTypes.number,
            isComplete: PropTypes.bool,
            image: PropTypes.shape({ id: PropTypes.string }),
            recipientName: PropTypes.string,
            groupType: PropTypes.string,
            metricName: PropTypes.string,
        })
    ).isRequired,
};

export default SearchResults;
