import { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

/**
 * A render-prop component for easy management of
 * expand/collapse content.
 *
 * For information on render-prop components, see:
 * https://reactjs.org/docs/render-props.html
 */
class Collapsible extends Component {
    static propTypes = {
        /**
         * Render function
         * @param {bool} props.isCollapsed Whether the widget is in a collapsed state
         * @param {bool} props.isExpanded Inverse of `isCollapsed` for convenience
         * @param {func} props.expand Expands widget (unless `locked`)
         * @param {func} props.collapse Collapses widget (unless `locked`)
         * @param {func} props.toggle Toggles widget (unless `locked`)
         */
        children: PropTypes.func.isRequired,

        /** Prevent the widget from expanding/collapsing */
        locked: PropTypes.bool,

        /** Whether the component should be collapsed on intial mount */
        startCollapsed: PropTypes.bool,
    };

    static defaultProps = {
        locked: false,
        startCollapsed: true,
    };

    state = {
        collapsed: this.props.startCollapsed, // eslint-disable-line react/destructuring-assignment
    };

    toggle = () =>
        this.setState(prev => ({
            collapsed: !prev.collapsed,
        }));

    expand = () => this.setState(() => ({ collapsed: false }));

    collapse = () => this.setState(() => ({ collapsed: true }));

    render() {
        const { locked, children } = this.props;
        const { collapsed } = this.state;

        return children({
            isCollapsed: collapsed,
            isExpanded: !collapsed,
            toggle: locked ? noop : this.toggle,
            expand: locked ? noop : this.expand,
            collapse: locked ? noop : this.collapse,
        });
    }
}

export default Collapsible;
