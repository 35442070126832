import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import posed from 'react-pose';

import Collapsible from 'common/collapsible';
import Link from 'common/link';

const SectionPose = posed.div({
    expanded: {
        height: 'auto',
        delayChildren: 100,
        staggerChildren: 25,
    },
    collapsed: {
        height: 0,
        staggerChildren: 25,
    },
});

const LinkPose = posed.div({
    expanded: { translateY: 0, opacity: 1 },
    collapsed: { translateY: -10, opacity: 0 },
});

class Sitemap extends Component {
    static propTypes = {
        showHome: PropTypes.bool,
        showSignIn: PropTypes.bool,
        collapsible: PropTypes.bool,
        className: PropTypes.string,
        sectionClassName: PropTypes.string,
        titleClassName: PropTypes.string,
        linkClassName: PropTypes.string,
    };

    static defaultProps = {
        showHome: false,
        showSignIn: false,
        collapsible: false,
        className: '',
        sectionClassName: '',
        titleClassName: '',
        linkClassName: '',
    };

    get classes() {
        const { sectionClassName, titleClassName, linkClassName, collapsible } = this.props;

        return {
            section: cx('relative pr-8 text-xs font-medium', sectionClassName),
            title: cx(
                'text-sm uppercase font-bold tracking-wide leading-tight text-white',
                titleClassName,
                { 'cursor-auto': !collapsible }
            ),
            link: cx('block my-4 text-gray-base hover:text-white', linkClassName),
        };
    }

    renderStandalone({ url, label, legacy }) {
        return (
            <section className={this.classes.section}>
                <Link to={url} className={this.classes.title} legacy={legacy}>
                    {label}
                </Link>
            </section>
        );
    }

    renderSection({ title, links }) {
        const { collapsible } = this.props;

        return (
            <Collapsible key={title} locked={!collapsible} startCollapsed={collapsible}>
                {({ isExpanded, toggle, collapse }) => (
                    <section className={this.classes.section}>
                        <h4>
                            <button type="button" className={this.classes.title} onClick={toggle}>
                                {title}
                            </button>
                        </h4>
                        <SectionPose
                            className="overflow-hidden"
                            pose={isExpanded ? 'expanded' : 'collapsed'}
                        >
                            {links.map(link => (
                                <LinkPose key={link.label}>
                                    <Link
                                        className={this.classes.link}
                                        to={link.url}
                                        onClick={collapse}
                                        legacy
                                    >
                                        {link.label}
                                    </Link>
                                </LinkPose>
                            ))}
                        </SectionPose>
                    </section>
                )}
            </Collapsible>
        );
    }

    render() {
        const { showHome, showSignIn, className } = this.props;

        return (
            <nav className={className}>
                {showHome && this.renderStandalone({ url: '/', label: 'Home', legacy: true })}
                {this.renderStandalone({
                    url: 'https://support.pledgeit.org/hc/en-us/requests/new',
                    label: 'Contact Us',
                })}

                {showSignIn &&
                    this.renderStandalone({ url: '/sign-in', label: 'Sign In', legacy: false })}

                {this.renderStandalone({
                    url: '/choice',
                    label: 'Get Started',
                    legacy: true,
                    titleClassName: 'text-green',
                })}
            </nav>
        );
    }
}

export default Sitemap;
