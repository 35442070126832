import React from 'react';
import PropTypes from 'prop-types';
import posed from 'react-pose';

import Collapsible from 'common/collapsible';
import Avatar from 'common/avatar';
import UserNav from 'site/user-nav';

const UserNavPose = posed.div({
    expanded: {
        height: 'auto',
        delayChildren: 100,
        staggerChildren: 25,
    },
    collapsed: {
        height: 0,
        staggerChildren: 25,
    },
});

const AuthNav = ({ user }) => (
    <Collapsible>
        {({ toggle, isExpanded }) => (
            <div>
                <button
                    type="button"
                    className="bg-gray-dark py-8 px-6 flex items-center w-full text-left leading-normal relative z-10"
                    onClick={toggle}
                >
                    <Avatar
                        firstName={user.firstName}
                        lastName={user.lastName}
                        imageId={user.avatar.id}
                        className="inline-block rounded-full w-14 h-14"
                    />
                    <div className="flex-grow pl-4">
                        <p className="text-regular font-medium text-white">
                            {user.firstName} {user.lastName}
                        </p>
                        <p className="text-xs font-medium text-gray-light truncate">{user.email}</p>
                    </div>
                </button>
                <UserNavPose
                    className="relative z-0 overflow-hidden"
                    pose={isExpanded ? 'expanded' : 'collapsed'}
                >
                    <UserNav
                        classNames={{
                            container: 'bg-gray-darker p-6',
                            link: 'block text-sm text-white font-medium py-3',
                        }}
                    />
                </UserNavPose>
            </div>
        )}
    </Collapsible>
);

AuthNav.propTypes = {
    user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        avatar: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }),
};

AuthNav.defaultProps = {
    user: {},
};

export default AuthNav;
