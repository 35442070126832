import { useState } from 'react';
import root from 'window-or-global';
import useDidMount from '@rooks/use-did-mount';
import useWillUnmount from '@rooks/use-will-unmount';
import throttle from 'lodash/throttle';

import config from 'config';

export const breakpoints = {
    sm: Number(config('/tailwind/screens/sm/min').replace('px', '')),
    md: Number(config('/tailwind/screens/md/min').replace('px', '')),
    lg: Number(config('/tailwind/screens/lg/min').replace('px', '')),
    xl: Number(config('/tailwind/screens/xl/min').replace('px', '')),
    xxl: Number(config('/tailwind/screens/xxl/min').replace('px', '')),
    xxxl: Number(config('/tailwind/screens/xxxl/min').replace('px', '')),
};

export default (initialWidth = 320) => {
    const [windowSize, setWindowSize] = useState(initialWidth);
    const handleResize = throttle(() => setWindowSize(window.innerWidth), 250);

    useDidMount(() => {
        root.addEventListener('resize', handleResize);
        handleResize();
    });

    useWillUnmount(() => {
        root.removeEventListener('resize', handleResize);
        handleResize.cancel();
    });

    return {
        width: windowSize,
        sm: breakpoints.sm <= windowSize,
        md: breakpoints.md <= windowSize,
        lg: breakpoints.lg <= windowSize,
        xl: breakpoints.xl <= windowSize,
        xxl: breakpoints.xxl <= windowSize,
        xxxl: breakpoints.xxxl <= windowSize,
    };
};
