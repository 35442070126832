import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

/**
 * Pretty straight-forward button component. Makes no
 * assumptions about text size, weight, or other styling.
 */
const Button = ({ component: Component, color, size, outline, className, ...rest }) => (
    <Component
        className={cx(
            'button',
            {
                'button--outline': outline,
                [`button--${color}`]: color,
                [`button--${size}`]: size,
            },
            className
        )}
        data-testid="button"
        {...rest}
    />
);

Button.propTypes = {
    /** Color scheme */
    color: PropTypes.oneOf([
        'green',
        'blue',
        'red',
        'orange',
        'gray',
        'dark',
        'light',
        'white',
        'facebook',
        'google',
        'twitter',
        'linkedin',
        'pinterest',
    ]),

    /** Controls the amount of padding  */
    size: PropTypes.oneOf(['sm', 'md', 'lg']),

    /** Use outline style instead of solid color theme */
    outline: PropTypes.bool,

    /** @ignore */
    className: PropTypes.string,

    /** Tag name or Component class to be used */
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
};

Button.defaultProps = {
    color: null,
    size: null,
    outline: false,
    className: '',
    component: 'button',
};

export default Button;
