import React, { useState } from 'react';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';

import SiteHeader from 'site/header';
import SiteFooter from 'site/footer';
import MobileNav from 'site/mobile-nav';
import MobileSearch from 'site/mobile-search';
import ErrorView from 'site/error';
import ErrorBoundary from 'common/error-boundary';
import SmallScreen from 'common/small-screen';

const MobilePose = posed.div();

const ApplicationPage = ({ header, footer, children }) => {
    const [isSearching, setIsSearching] = useState(false);
    const [isShowingMobileMenu, setIsShowingMobileMenu] = useState(false);

    return (
        <div className="min-h-screen flex flex-col">
            <SiteHeader
                minimal={header === 'minimal'}
                isShowingMobileMenu={isShowingMobileMenu}
                isSearching={isSearching}
                toggleMobileMenu={setIsShowingMobileMenu}
                toggleSearch={setIsSearching}
            />

            <div className="flex-grow flex flex-col">
                <ErrorBoundary fallback={ErrorView}>{children}</ErrorBoundary>
            </div>

            {footer && <SiteFooter />}

            <SmallScreen>
                <PoseGroup withParent={false}>
                    {isShowingMobileMenu && (
                        <MobilePose key="mobile-menu">
                            <MobileNav />
                        </MobilePose>
                    )}
                    {isSearching && (
                        <MobilePose key="mobile-search">
                            <MobileSearch onClose={() => setIsSearching(false)} />
                        </MobilePose>
                    )}
                </PoseGroup>
            </SmallScreen>
        </div>
    );
};

ApplicationPage.propTypes = {
    header: PropTypes.oneOf(['default', 'minimal']),
    footer: PropTypes.oneOf(['default', null]),
    children: PropTypes.node,
};

ApplicationPage.defaultProps = {
    header: 'default',
    footer: 'default',
    children: null,
};

export default ApplicationPage;
