import PropTypes from 'prop-types';

import useBreakpoint from 'hooks/use-breakpoint';

/**
 * A component which will only render its children when
 * the browser is wider than the large breakpoint.
 * Inverse of the SmallScreen component, utilizes the
 * `<ScreenSize>` render-prop component under the hood.
 */
const LargeScreen = ({ children }) => {
    const { lg } = useBreakpoint();
    return lg ? children : null;
};

LargeScreen.propTypes = {
    /** Node(s) to be rendered on large screens */
    children: PropTypes.node,
};

LargeScreen.defaultProps = {
    children: null,
};

export default LargeScreen;
