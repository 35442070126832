import React from 'react';
import posed from 'react-pose';
import { adopt } from 'react-adopt';

import { GlobalConsumer } from 'site/context';
import Sitemap from 'site/sitemap';
import useScrollLock from 'hooks/use-scroll-lock';
import AuthNav from './auth-nav';

const NavPose = posed.div({
    enter: { opacity: 1, x: 0, transition: { type: 'tween' } },
    exit: { opacity: 0, x: -50, transition: { type: 'tween' } },
});

/* eslint-disable */
const MobileNavState = adopt(
    {
        global: <GlobalConsumer />,
    },
    ({ global }) => ({ ...global })
);
/* eslint-enable */

const MobileNav = () => {
    useScrollLock();

    return (
        <MobileNavState>
            {({ user }) => (
                <div className="fixed pin-y pin-l z-40">
                    <NavPose
                        key="mobile-nav"
                        className="h-full bg-gray-darkest flex flex-col overflow-auto"
                    >
                        <div className="flex-grow">
                            {user && <AuthNav user={user} />}
                            <Sitemap
                                className="min-w-sm py-4"
                                sectionClassName="px-6 py-4"
                                showSignIn={!user}
                                showHome
                                collapsible
                            />
                        </div>
                    </NavPose>
                </div>
            )}
        </MobileNavState>
    );
};

MobileNav.propTypes = {};

export default MobileNav;
