import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';
// import posed, { PoseGroup } from 'react-pose';

import LargeScreen from 'common/large-screen';
import SmallScreen from 'common/small-screen';
import Link from 'common/link';
import Button from 'common/button';
import Icon from 'common/icon';
// import Search from 'site/search';
// import MainNav from './main-nav';
import AuthNav from './auth-nav';

// const SearchPose = posed.div({
//     enter: { opacity: 1, x: 0, delay: 100 },
//     exit: { opacity: 0, x: 50 },
// });

// const NavPose = posed.div({
//     enter: { opacity: 1, delay: 100 },
//     exit: { opacity: 0 },
// });

const SiteHeader = ({
    minimal,
    toggleMobileMenu,
    isShowingMobileMenu,
    // toggleSearch,
    // isSearching,
}) => (
    <header className="bg-white px-8 py-6 z-40 sticky pin-t pin-x flex items-center shadow-md">
        <Link to="/" className="block pr-2" legacy>
            <Image
                publicId="logo/logo-key.svg"
                alt="Pledge It"
                className="block max-w-full"
                width="150"
            />
        </Link>

        {/* {!minimal && (
            <LargeScreen>
                <div className="ml-8 xl:ml-12">
                    <PoseGroup withParent={false}>
                        {isSearching ? (
                            <SearchPose key="search" className="w-sm">
                                <Search onClose={() => toggleSearch(false)} />
                            </SearchPose>
                        ) : (
                            <NavPose key="nav">
                                <MainNav onSearch={() => toggleSearch(true)} />
                            </NavPose>
                        )}
                    </PoseGroup>
                </div>
            </LargeScreen>
        )} */}

        <div className="flex flex-grow justify-end">
            <LargeScreen>
                <AuthNav allowRegister={!minimal} />
            </LargeScreen>
            <SmallScreen>
                <Button
                    size="sm"
                    className="text-gray-light -mr-3/4em"
                    onClick={() => toggleMobileMenu(!isShowingMobileMenu)}
                >
                    <Icon icon={isShowingMobileMenu ? 'times' : 'bars'} size="lg" fixedWidth />
                </Button>
            </SmallScreen>
        </div>
    </header>
);

SiteHeader.propTypes = {
    minimal: PropTypes.bool.isRequired,
    isShowingMobileMenu: PropTypes.bool.isRequired,
    toggleMobileMenu: PropTypes.func.isRequired,
    // isSearching: PropTypes.bool.isRequired,
    // toggleSearch: PropTypes.func.isRequired,
};

export default SiteHeader;
